import * as React from "react"
import { Helmet } from "react-helmet"
import {
  IoLogoTwitter,
  IoMailOutline,
  IoLogoGithub,
  IoLogoDribbble,
  IoLogoLinkedin,
} from "react-icons/io5"
import { StaticImage } from "gatsby-plugin-image"
import { FaProductHunt } from "react-icons/fa"

export default function Contact() {
  return (
    <main>
      <Helmet>
        <title>Contact</title>
      </Helmet>
      <section className="container">
        <div className="pt-6 pb-4 sm:pb-8 space-y-2 md:space-y-5">
          <h1>Contact</h1>
          <p className="text-lg leading-6 text-zinc-500">
            Feel free to reach out to me through whatever medium you find is
            easiest. I'm happy to answer questions, collaborate, or chat if I
            have the time.
          </p>
        </div>
        <div className="py-3 sm:py-5 md:pt-6 md:pb-12">
          <ul
            className="grid gap-8"
            style={{
              gridTemplateColumns: `repeat(auto-fit, minmax(260px, 1fr))`,
            }}
          >
            <li>
              <a
                className="text-amber-500 hover:text-amber-600"
                href="https://twitter.com/gill_kyle"
              >
                <div className="grid grid-flow-col auto-cols-max gap-x-3 rounded-2xl p-6 hover-card">
                  <div>
                    <div className="bg-sky-100 text-sky-300 rounded-full p-3">
                      <IoLogoTwitter size={32} />
                    </div>
                  </div>
                  <div className="flex flex-col justify-center">
                    <h2 className="text-xl text-zinc-600">Twitter</h2>
                    Tweet @ me →
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a
                className="text-amber-500 hover:text-amber-600"
                href="mailto:kylerobertgill@gmail.com"
              >
                <div className="grid grid-flow-col auto-cols-max gap-x-3 rounded-2xl p-6 hover-card">
                  <div>
                    <div className="bg-purple-100 text-purple-300 rounded-full p-3">
                      <IoMailOutline size={32} />
                    </div>
                  </div>
                  <div className="flex flex-col justify-center">
                    <h2 className="text-xl text-zinc-600">Email</h2>
                    Send me an email →
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a
                className="text-amber-500 hover:text-amber-600"
                href="https://github.com/gillkyle"
              >
                <div className="grid grid-flow-col auto-cols-max gap-x-3 rounded-2xl p-6 hover-card">
                  <div>
                    <div className="bg-zinc-100 text-zinc-300 rounded-full p-3">
                      <IoLogoGithub size={32} />
                    </div>
                  </div>
                  <div className="flex flex-col justify-center">
                    <h2 className="text-xl text-zinc-600">GitHub</h2>
                    Profile on GitHub →
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a
                className="text-amber-500 hover:text-amber-600"
                href="https://dribbble.com/kylegill"
              >
                <div className="grid grid-flow-col auto-cols-max gap-x-3 rounded-2xl p-6 hover-card">
                  <div>
                    <div className="bg-pink-100 text-pink-300 rounded-full p-3">
                      <IoLogoDribbble size={32} />
                    </div>
                  </div>
                  <div className="flex flex-col justify-center">
                    <h2 className="text-xl text-zinc-600">Dribbble</h2>
                    Profile on Dribbble →
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a
                className="text-amber-500 hover:text-amber-600"
                href="https://producthunt.com/@gill_kyle/made"
              >
                <div className="grid grid-flow-col auto-cols-max gap-x-3 rounded-2xl p-6 hover-card">
                  <div>
                    <div className="bg-orange-100 text-orange-300 rounded-full p-3">
                      <FaProductHunt size={32} />
                    </div>
                  </div>
                  <div className="flex flex-col justify-center">
                    <h2 className="text-xl text-zinc-600">ProductHunt</h2>
                    View my products →
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a
                className="text-amber-500 hover:text-amber-600"
                href="https://www.linkedin.com/in/kyle-gill"
              >
                <div className="grid grid-flow-col auto-cols-max gap-x-3 rounded-2xl p-6 hover-card">
                  <div>
                    <div className="bg-blue-100 text-blue-300 rounded-full p-3">
                      <IoLogoLinkedin size={32} />
                    </div>
                  </div>
                  <div className="flex flex-col justify-center">
                    <h2 className="text-xl text-zinc-600">LinkedIn</h2>
                    Connect with me →
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div className="illustration">
          <StaticImage
            alt="Binoculars illustration"
            src="../images/binocs.png"
            layout="FIXED"
            width={180}
            placeholder="TRACED_SVG"
            formats={["auto", "webp", "avif"]}
          />
        </div>
      </section>
    </main>
  )
}
